
import {defineComponent} from 'vue'
import DataTable from "@/components/datatable/DataTable.vue";
import {ChartType} from "@/core/config/Constant";
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: "DashboardWidgets",
  components: {DataTable},
  props: {
    userId: {}
  },
  setup() {
    const {t} = useI18n();
    return {ChartType, t}
  }
})
