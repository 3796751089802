import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b347486"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "dashboard-grid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_ctx.userId)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_DataTable, {
          clazz: `mb-3`,
          "show-if-empty": false,
          "view-more-link": '/my-tasks',
          "is-dashboard-widget": true,
          params: {code : 'HOME_TASKS', type :_ctx.ChartType.DATA_TABLE, title:'My Tasks', params: {userId:_ctx.userId}}
        }, null, 8, ["params"]),
        _createVNode(_component_DataTable, {
          clazz: `mb-3`,
          "show-if-empty": false,
          "view-more-link": '/my-reminders',
          "is-dashboard-widget": true,
          params: {code : 'HOME_REMINDERS', type :_ctx.ChartType.DATA_TABLE, title:'My Reminders', params: {userId:_ctx.userId}}
        }, null, 8, ["params"]),
        _createVNode(_component_DataTable, {
          clazz: `mb-3`,
          "show-if-empty": false,
          "view-more-link": '/team/scans',
          "is-dashboard-widget": true,
          params: {code : 'HOME_CLAIMS_READY_DECISION', type :_ctx.ChartType.DATA_TABLE, title:'My Claims Waiting Decision', params: {userId:_ctx.userId}}
        }, null, 8, ["params"]),
        _createVNode(_component_DataTable, {
          clazz: `mb-3`,
          "show-if-empty": false,
          "view-more-link": 'claim-reminders-due',
          "is-dashboard-widget": true,
          params: {code : 'HOME_CLAIMS_REMINDER_DUE', type :_ctx.ChartType.DATA_TABLE, title:'My Claim Documents Reminders Due', params: {userId:_ctx.userId}}
        }, null, 8, ["params"]),
        _createVNode(_component_DataTable, {
          clazz: `mb-3`,
          "show-if-empty": false,
          "view-more-link": 'claim-reminders-due',
          "is-dashboard-widget": true,
          params: {code : 'HOME_CLAIMS_WAITING_APPROVAL', type :_ctx.ChartType.DATA_TABLE, title:'Claims Waiting My Approval', params: {userId:_ctx.userId}}
        }, null, 8, ["params"]),
        _createVNode(_component_DataTable, {
          clazz: `mb-3`,
          "show-if-empty": false,
          "view-more-link": '/team/scans',
          "is-dashboard-widget": true,
          params: {code : 'HOME_DATA_PROBLEMS', type :_ctx.ChartType.DATA_TABLE, title:'My Data Problems', params: {userId:_ctx.userId}}
        }, null, 8, ["params"]),
        _createVNode(_component_DataTable, {
          clazz: `mb-3`,
          "show-if-empty": false,
          "view-more-link": '/list-process/MEDICAL_REVIEW/List',
          "is-dashboard-widget": true,
          params: {code : 'HOME_MY_PROCESS', type :_ctx.ChartType.DATA_TABLE, title:'Medical Reviews Assigned to Me', params: {userId:_ctx.userId, processType:'MEDICAL_REVIEW'}}
        }, null, 8, ["params"]),
        _createVNode(_component_DataTable, {
          clazz: `mb-3`,
          "show-if-empty": false,
          "view-more-link": '/list-process/COMPLAINT/List',
          "is-dashboard-widget": true,
          params: {code : 'HOME_MY_PROCESS', type :_ctx.ChartType.DATA_TABLE, title:'Complaints Assigned to Me', params: {userId:_ctx.userId, processType:'COMPLAINT'}}
        }, null, 8, ["params"]),
        _createVNode(_component_DataTable, {
          clazz: `mb-3`,
          "show-if-empty": false,
          "view-more-link": '/list-process/INQUIRY/List',
          "is-dashboard-widget": true,
          params: {code : 'HOME_MY_PROCESS', type :_ctx.ChartType.DATA_TABLE, title:'Inquiries Assigned to Me', params: {userId:_ctx.userId, processType:'INQUIRY'}}
        }, null, 8, ["params"]),
        _createVNode(_component_DataTable, {
          clazz: `mb-3`,
          "show-if-empty": false,
          "view-more-link": '/list-process/APPEAL/List',
          "is-dashboard-widget": true,
          params: {code : 'HOME_MY_PROCESS', type :_ctx.ChartType.DATA_TABLE, title:'Appeals Assigned to Me', params: {userId:_ctx.userId, processType:'APPEAL'}}
        }, null, 8, ["params"]),
        _createVNode(_component_DataTable, {
          clazz: `mb-3`,
          "show-if-empty": false,
          "view-more-link": '/list-process/ISSUE/List',
          "is-dashboard-widget": true,
          params: {code : 'HOME_MY_PROCESS', type :_ctx.ChartType.DATA_TABLE, title:'Issues Assigned to Me', params: {userId:_ctx.userId, processType:'ISSUE'}}
        }, null, 8, ["params"]),
        _createVNode(_component_DataTable, {
          clazz: `mb-3`,
          "show-if-empty": false,
          "view-more-link": '/list-process/ESCALATION/List',
          "is-dashboard-widget": true,
          params: {code : 'HOME_MY_PROCESS', type :_ctx.ChartType.DATA_TABLE, title:'Escalations Assigned to Me', params: {userId:_ctx.userId, processType:'ESCALATION'}}
        }, null, 8, ["params"])
      ]))
    : _createCommentVNode("", true)
}